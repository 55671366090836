import { graphql, PageProps } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import Layout from '../../components/organisms/layout'
import { CategoryKind } from '../../types/CategoryKind'
import CommonH3 from '../../components/atoms/commonH3'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  &.item-wrap {
    margin-bottom: 50px;
  }

  .category-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 30px;
    max-width: 650px;
    width: 90vw;
    margin: 15px auto;
    // float: left;
  }

  h3 {
    span {
      font-size: 1.6em;
      border: none;

      &:before {
        display: none;
      }
    }
  }

  .category-list {
    margin-top: 15px;
    display: flex;
    gap: 10px 50px;
    padding: 0 20px;
    flex-wrap: wrap;

    li {
      display: block;
      min-width: 120px;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 7px;
        left: -14px;
        border-left: 8px solid #3ec7b3;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      a {
        max-width: 100%;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        display: inline-block;
      }
    }
  }

  .all-link {
    display: block;
    width: fit-content;
    margin: 0 0 0 auto;
    text-align: right;
    color: #00b5a6;
  }

  @media screen and (max-width: 1074px) {
    margin: 15px auto;
    width: 80vw;
    float: unset;
    min-height: unset;
  }

  ${BreakPoints.large} {
    width: calc(100vw - 45px);
  }
`

type IProps = PageProps<GatsbyTypes.HelpIndexPageQuery>

/**
 * マニュアルダウンロード
 * @constructor
 */
const ManualDownloadPage = ({ location }: IProps) => {
  // GraphQLで取得したデータを保持
  // const items = data.awsGraphQL.listHelpCategories?.items || []

  const paths: ITopicPath[] = [
    { title: 'TOP', link: '/help' },
    { title: 'マニュアルダウンロード' },
  ]
  return (
    <Layout location={location} paths={paths} categoryKind={CategoryKind.FIRST}>
      <SEO title="マニュアルダウンロード" />
      <CommonH2 label="マニュアルダウンロード" />
      <p className="txt-center mt-020 mb-020">
        キンクラの使い方に関してのマニュアルです。
        <br />
        キンクラ導入の際に、せひご活用ください。
        <span
          style={{ opacity: 0.5, margin: '30px 30px -10px', display: 'block' }}
        >
          ※以下のリンクをクリックするとマニュアルファイル(pptx)がダウンロードできます。
        </span>
      </p>
      <Wrapper className="item-wrap">
        <Wrapper className="category-card">
          <CommonH3 label="従業員向けマニュアル" className="category-title" />
          <ul className="category-list">
            <li>
              <a href="/downloads/howto_kintaicloud_fix_v1.pptx">
                固定労働時間制
              </a>
            </li>
            <li>
              <a href="/downloads/howto_kintaicloud_transformational_v1.pptx">
                変形労働時間制
              </a>
            </li>
            <li>
              <a href="/downloads/howto_kintaicloud_flex_v1.pptx">
                フレックス時間制
              </a>
            </li>
          </ul>
        </Wrapper>
        <Wrapper className="category-card">
          <CommonH3 label="管理職向けマニュアル" className="category-title" />
          <ul className="category-list">
            <li>
              <a href="/downloads/howto_kintaicloud_manager_v1.pptx">
                管理職向けマニュアル
              </a>
            </li>
          </ul>
        </Wrapper>
        <Wrapper className="category-card">
          <CommonH3 label="総務向けマニュアル" className="category-title" />
          <ul className="category-list">
            <li>
              <a href="/downloads/howto_kintaicloud_general_v1.pptx">
                総務向けマニュアル
              </a>
            </li>
          </ul>
        </Wrapper>
        {/* <Wrapper className="category-card"> */}
        {/*   <CommonH3 label="管理職向けマニュアル" className="category-title" /> */}
        {/*   <ul className="category-list"> */}
        {/*     <li> */}
        {/*       <Link href="#">管理職向けマニュアル</Link> */}
        {/*     </li> */}
        {/*   </ul> */}
        {/* </Wrapper> */}
        {/* <Wrapper className="category-card"> */}
        {/*   <CommonH3 label="総務向けマニュアル" className="category-title" /> */}
        {/*   <ul className="category-list"> */}
        {/*     <li> */}
        {/*       <Link>総務向けマニュアル</Link> */}
        {/*     </li> */}
        {/*   </ul> */}
        {/* </Wrapper> */}
      </Wrapper>
    </Layout>
  )
}

export default ManualDownloadPage

export const pageQuery = graphql`
  query ManualDownloadPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
